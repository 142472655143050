import React, { createContext, useContext, useState, useCallback, ReactNode, useMemo } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { CheckCircle, ErrorRounded, InfoRounded, WarningRounded } from '@mui/icons-material';

interface AlertDeleteContextProps {
  /**
   * @name handleAlert
   * @description function that opens alert dialog
   * @param {String} variant - Alert type 'warning' | 'error' | 'success' | 'info'
   * @param {String|ReactNode} text - Alert text
   * @param {string} cancelText - Cancel button text
   * @param {string} confirmText - Confirmation button text
   * @param {Function} onAction - Function that is called upon confirmation
   * @returns {void}
   */
  handleAlert: (props: any) => void;
  handleClose: () => void;
}

interface ActionProps {
  variant: 'warning' | 'error' | 'success' | 'info';
  text: string | ReactNode;
  cancelText?: string;
  confirmText?: string;
  onAction: () => void;
}

const AlertDeleteContext = createContext<AlertDeleteContextProps | undefined>(undefined);

interface AlertDeleteProviderProps {
  children: ReactNode;
}

export const AlertDeleteProvider: React.FC<AlertDeleteProviderProps> = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<ActionProps>();

  const handleAlert = useCallback(
    ({ variant, onAction, text, confirmText, cancelText }: ActionProps) => {
      setOpen(true);
      setOptions({ variant, onAction, text, confirmText, cancelText });
    },
    []
  );

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleAction = useCallback(() => {
    options?.onAction();
    setOpen(false);
  }, [options?.onAction]);

  const value = useMemo(() => ({ handleAlert, handleClose }), [handleAlert, handleClose]);

  let icon;
  let color;
  let name;
  if (options) {
    switch (options.variant) {
      case 'warning':
        name = 'Внимание';
        color = '#FFA000';
        icon = <WarningRounded color="warning" />;
        break;
      case 'error':
        name = 'Ошибка';
        color = '#D32F2F';
        icon = <ErrorRounded color="error" />;
        break;
      case 'success':
        name = 'Успешно';
        color = '#388E3C';
        icon = <CheckCircle color="success" />;
        break;
      case 'info':
        name = 'Информация';
        color = '#1976D2';
        icon = <InfoRounded color="info" />;
        break;
      default:
        name = 'Информация';
        color = '#1976D2';
        icon = <InfoRounded color="info" />;
    }
  }

  return (
    <AlertDeleteContext.Provider value={value}>
      {children}
      <Dialog open={open}>
        <DialogTitle display="flex" alignItems="center" color={color}>
          {icon}
          <div style={{ marginLeft: 10, marginTop: 3 }}>{name}</div>
        </DialogTitle>
        <DialogContent>{options?.text}</DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {options?.cancelText || 'Отмена'}
          </Button>
          <Button onClick={handleAction} color="secondary">
            {options?.confirmText || 'Подтвердить'}
          </Button>
        </DialogActions>
      </Dialog>
    </AlertDeleteContext.Provider>
  );
};

export const useAlert = () => {
  const context = useContext(AlertDeleteContext);
  if (!context) {
    throw new Error('useAlertDelete must be used within an AlertDeleteProvider');
  }
  return context;
};
