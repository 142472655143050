import React, { ReactElement } from 'react';
import styled from '@emotion/styled';
import { useMutation } from '@apollo/client';
import { Button, TextField } from '@mui/material';
import { Form, Formik } from 'formik';
import { enqueueSnackbar } from 'notistack';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import * as Yup from 'yup';

import { GQL_GET_ME, GQL_LOGIN } from '../graphql/queries';

const Login = (): ReactElement => {
  const [login, { loading }] = useMutation(GQL_LOGIN);

  const onSubmit = async (values: any, { setErrors }: { setErrors: (object: object) => void }) => {
    const response = await login({
      variables: values,
      refetchQueries: [
        {
          query: GQL_GET_ME,
        },
      ],
    });
    const errors = _.get(response, 'errors', []);
    if (!_.isEmpty(errors)) {
      setErrors(_.get(errors, '0.extensions', {}));
    } else {
      enqueueSnackbar('Добро пожаловать!', { variant: 'success' });
    }
  };

  const validationSchema = Yup.object().shape({
    username: Yup.string().required('Email или логин обязателен для заполнения'),
    password: Yup.string().required('Пароль обязателен для заполнения'),
  });

  const initialValues: LoginFormValues = {
    username: '',
    password: '',
  };

  return (
    <Main>
      <LoginForm>
        <H1>Авторизация</H1>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          enableReinitialize
          validationSchema={validationSchema}
        >
          {({ errors, touched, values, handleChange, handleBlur }) => (
            <FormStyled>
              <FormGroup>
                <TextField
                  fullWidth
                  id="username"
                  name="username"
                  label="Логин или Email"
                  variant="standard"
                  value={values.username}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={Boolean(touched.username && errors.username)}
                  helperText={String((touched.username && errors.username) || '')}
                  disabled={loading}
                />
              </FormGroup>
              <FormGroup>
                <TextField
                  fullWidth
                  id="password"
                  name="password"
                  label="Пароль"
                  type="password"
                  variant="standard"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={Boolean(touched.password && errors.password)}
                  helperText={String((touched.password && errors.password) || '')}
                  disabled={loading}
                />
              </FormGroup>
              <Button
                size="large"
                variant="contained"
                disabled={loading}
                type="submit"
                className="w-100"
              >
                Отправить
              </Button>
              <LinkTo to="/register">Регистрация</LinkTo>
            </FormStyled>
          )}
        </Formik>
      </LoginForm>
    </Main>
  );
};

const H1 = styled.h1`
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
  color: #000;
`;

const FormStyled = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;

  .form-control {
    width: 100%;
    background-color: #f3f3f3;
  }

  .btn {
    width: 100%;
  }
`;

const LinkTo = styled(Link)`
  text-align: center;
  margin-top: 10px;
  color: #595959;
`;

const FormGroup = styled.div`
  width: 100%;
  margin-bottom: 20px;
`;

const Main = styled.div`
  background-color: #e7ecfc;
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  &:after {
    content: '';
    z-index: 2;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.35);
  }
`;

const LoginForm = styled.div`
  position: relative;
  z-index: 3;
  background-color: rgba(255, 255, 255, 0.85);
  max-width: 500px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  padding: 90px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
`;

export default Login;
