import { gql } from '@apollo/client';

export const GQL_GET_HISTORIES = gql`
  query GetHistories($query: QueryItems) {
    histories(query: $query) {
      total
      items {
        _id
        model
        model_id
        action
        created_at
        owner {
          _id
          name
        }
      }
    }
  }
`;

export const GQL_GET_HISTORY = gql`
  query GetHistory($_id: ID!) {
    history(_id: $_id) {
      _id
      model
      model_id
      action
      owner {
        _id
        name
      }
      created_at
      content
    }
  }
`;

export const GQL_CLEAR_HISTORY = gql`
  mutation HistoryDelete {
    historyDelete
  }
`;

export const GQL_REVERT_HISTORY = gql`
  mutation HistoryRevert($_id: ID!) {
    historyRevert(_id: $_id)
  }
`;
