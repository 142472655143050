import { Logout } from '@mui/icons-material';
import React from 'react';
import _ from 'lodash';
import { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar/AppBar';
import styled from '@emotion/styled';
import MuiAppBar from '@mui/material/AppBar';
import { Button, IconButton, Toolbar } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from 'react-router-dom';
import { config } from '../config';

const drawerWidth: number = 240;

interface AppBarProps extends MuiAppBarProps {
  open: boolean;
}

interface TopBarProps {
  open: boolean;
  toggle: () => void;
  me: any;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
  // @ts-ignore Emotion css prop
})<AppBarProps>(({ theme: { zIndex, transitions }, open }) => ({
  zIndex: zIndex.drawer + 1,
  transition: transitions.create(['width', 'margin'], {
    easing: transitions.easing.sharp,
    duration: transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: transitions.create(['width', 'margin'], {
      easing: transitions.easing.sharp,
      duration: transitions.duration.enteringScreen,
    }),
  }),
}));

const TopBar = ({ open, toggle, me }: TopBarProps) => {
  return (
    <AppBar position="absolute" open={open}>
      <Toolbar
        style={{ background: 'var(--metalic)', color: 'var(--dark)' }}
        sx={{
          pr: '28px', // keep right padding when drawer closed
        }}
      >
        <Left>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={toggle}
            sx={{
              marginRight: '36px',
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Link to="/">
            <Image src="/assets/images/logo.svg" alt="logo" />
          </Link>
        </Left>
        <Right>
          <LinkOut
            as="a"
            href={`${_.get(config, 'url.schema', '')}://${_.get(config, 'url.domainFrontend', '')}`}
            target="_blank"
            rel="noreferrer"
          >
            Перейти на сайт
          </LinkOut>
          <LinkStyle>{_.get(me, 'name', 'Пользователь')}</LinkStyle>
          <Button
            color="inherit"
            onClick={() => {
              window.location.href = '/api/auth/logout';
            }}
          >
            <Logout style={{ marginRight: 10 }} />
            Выход
          </Button>
        </Right>
      </Toolbar>
    </AppBar>
  );
};

const Left = styled.div`
  display: flex;
  align-items: center;
`;

const Right = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
`;

const Image = styled.img`
  height: 30px;
  width: auto;
`;

const LinkOut = styled.a`
  margin-right: 20px;
  color: var(--dark);
  display: block;
  text-decoration: none;
`;

const LinkStyle = styled.div`
  margin-right: 20px;
  text-decoration: none;
`;

export default TopBar;
