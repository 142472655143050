export const ruLang = {
  common: {
    ok: 'ОК',
    delete: 'Удалить',
    enter: 'Ввод',
  },

  blockQuote: {
    title: 'Цитата',
  },
  codeBlock: {
    title: 'Блок кода',
  },
  color: {
    color: 'Цвет шрифта',
    bgColor: 'Цвет фона',
    default: 'Цвет по умолчанию',
    clear: 'Очистить цвет фона',
  },
  divider: {
    title: 'Разделитель',
  },
  emotion: {
    title: 'Эмоция',
  },
  fontSize: {
    title: 'Размер',
    default: 'Размер',
  },
  fontFamily: {
    title: 'Шрифт',
    default: 'Шрифт',
  },
  fullScreen: {
    title: 'Полноэкранный режим',
  },
  header: {
    title: 'Заголовок',
    text: 'Текст',
  },
  image: {
    netImage: 'Изображение из интернета',
    delete: 'Удалить изображение',
    edit: 'Редактировать изображение',
    viewLink: 'Посмотреть ссылку',
    src: 'Источник изображения',
    desc: 'Описание',
    link: 'Ссылка на изображение',
  },
  indent: {
    decrease: 'Уменьшить',
    increase: 'Увеличить',
  },
  justify: {
    left: 'Влево',
    right: 'Вправо',
    center: 'По центру',
    justify: 'По ширине',
  },
  lineHeight: {
    title: 'Высота',
    default: 'Высота',
  },
  link: {
    insert: 'Вставить ссылку',
    text: 'Текст ссылки',
    url: 'URL ссылки',
    unLink: 'Удалить ссылку',
    edit: 'Редактировать ссылку',
    view: 'Посмотреть ссылку',
  },
  textStyle: {
    bold: 'Жирный',
    clear: 'Очистить стили',
    code: 'Встроенный код',
    italic: 'Курсив',
    sub: 'Нижний индекс',
    sup: 'Верхний индекс',
    through: 'Зачеркнутый',
    underline: 'Подчеркнутый',
  },
  tableModule: {
    insertTable: 'Вставить таблицу',
    header: 'Заголовок',
    widthAuto: 'Ширина авто',
    insertRow: 'Вставить строку',
    deleteRow: 'Удалить строку',
    insertCol: 'Вставить столбец',
    deleteCol: 'Удалить столбец',
    deleteTable: 'Удалить таблицу',
  },
  videoModule: {
    insertVideo: 'Вставить видео',
    uploadVideo: 'Загрузить видео',
  },
  listModule: {
    orderedList: 'Нумерованный список',
    unOrderedList: 'Маркированный список',
  },
  uploadImgModule: {
    uploadImage: 'Загрузить изображение',
  },
  undo: {
    undo: 'Отменить',
    redo: 'Повторить',
  },
  todo: {
    todo: 'Список дел',
  },
};
