import _ from 'lodash';

import { config } from '../config';
import { IMAGE_SIZE_MEDIUM, IMAGE_SIZE_SMALL } from './constants';

/**
 * @function
 * @name formatPrice
 * @description Formatting price
 * @param price
 */
export const formatPrice = (price?: number | string): string => {
  if (!price && !_.isNumber(price)) {
    return '';
  }
  const priceNumber = typeof price === 'string' ? parseInt(price, 10) : price;
  return `${priceNumber.toLocaleString('ru-RU')} ₽`;
};
/**
 * @function
 * @name sortByKey
 * @description Sorting array by names a-z
 * @param {object} a from
 * @param {object} b to
 * @param {string} key name for sorting
 * @return {int} rule for sorting function
 * @example .sort((a,b) => sortByNames(a, b))
 */

export const sortByKey = (a?: any, b?: any, key: string = 'name') => {
  const valueA = _.get(a, key, '').trim().toString().toLowerCase();
  const valueB = _.get(b, key, '').trim().toString().toLowerCase();

  if (valueA < valueB) {
    return -1;
  }
  if (valueA > valueB) {
    return 1;
  }
  return 0;
};
/**
 * Маппинг кириллических символов в латинские
 * @type {Object}
 * @returns {String} - латинский символ
 */
const cyrillicToLatinMap: { [key: string]: string } = {
  а: 'a',
  б: 'b',
  в: 'v',
  г: 'g',
  д: 'd',
  е: 'e',
  ё: 'yo',
  ж: 'zh',
  з: 'z',
  и: 'i',
  й: 'y',
  к: 'k',
  л: 'l',
  м: 'm',
  н: 'n',
  о: 'o',
  п: 'p',
  р: 'r',
  с: 's',
  т: 't',
  у: 'u',
  ф: 'f',
  х: 'kh',
  ц: 'ts',
  ч: 'ch',
  ш: 'sh',
  щ: 'sch',
  ъ: '',
  ы: 'y',
  ь: '',
  э: 'e',
  ю: 'yu',
  я: 'ya',
};
/**
 * @function
 * @name getImageUrls
 * @description Getting image urls
 * @param item - file
 * @returns {Object} - image urls
 * @example getImageUrls(item)
 * @returns {Object} - { original: string, medium: string, thumbnail: string }
 */
export const getImageUrls = (
  item?: RealFile
): { original: string; medium: string; thumbnail: string } => {
  if (!item) {
    return { original: '', medium: '', thumbnail: '' };
  }
  return {
    original: `${config.url.cloud}/${item.model}/${item.path}.${item.format}`,
    medium: `${config.url.cloud}/${item.model}/${IMAGE_SIZE_MEDIUM}__${item.path}.${item.format}`,
    thumbnail: `${config.url.cloud}/${item.model}/${IMAGE_SIZE_SMALL}__${item.path}.${item.format}`,
  };
};

/**
 * @function
 * @name getVideoUrl
 * @description Getting video urls
 * @param item - file
 * @returns {Object} - video urls
 * @example getVideoUrl(item)
 * @returns {Object} - { mp4: string, webm: string }
 */
export const getVideoUrl = (item?: RealFile): { mp4: string; webm: string } => {
  if (!item) {
    return { mp4: '', webm: '' };
  }
  return {
    mp4: `${config.url.cloud}/${item.model}/${item.path}.mp4`,
    webm: `${config.url.cloud}/${item.model}/${item.path}.webm`,
  };
};

/**
 * @function
 * @name getFileUrl
 * @description Getting file url
 * @param item - file
 * @returns {string} - file url
 * @example getFileUrl(item)
 * @returns {string} - file url
 */
export const getFileUrl = (item?: RealFile): string => {
  if (!item) {
    return '';
  }
  return `${config.url.cloud}/${item.model}/${item.path}.${item.format}`;
};

/**
 * @function
 * @name generateSlug
 * @description Generating slug
 * @param name - name for slug
 * @param separator - separator for slug
 * @returns {string} - slug
 */
export const generateSlug = (name: string, separator: string = '-'): string => {
  return name
    .split('')
    .map((char) => cyrillicToLatinMap[char.toLowerCase()] || char)
    .join('')
    .toLowerCase()
    .replace(/[\s_]+/g, separator)
    .replace(/[^\w-]+/g, '')
    .replace(/-+/g, separator)
    .replace(/^-+/, '')
    .replace(/-+$/, '');
};
