import { gql } from '@apollo/client';

import { fileFragment, metaFragment } from './common';

const collectionListFragment = gql`
  fragment CollectionListFragment on Collection {
    _id
    name
    slug
    file_ids
    description
    status
    # Virtuals
    files {
      ...FileFragment
    }
  }
  ${fileFragment}
`;

const collectionFragment = gql`
  fragment CollectionFragment on Collection {
    _id
    meta {
      ...MetaFragment
    }
    name
    slug
    file_ids
    description
    status
    # Virtuals
    files {
      ...FileFragment
    }
#    products {
#      ...ProductListFragment
#    }
  }
  ${metaFragment}
  ${fileFragment}
`;

export const GQL_GET_COLLECTIONS = gql`
  query GetCollections($query: QueryItems) {
    collections(query: $query) {
      total
      items {
        ...CollectionListFragment
      }
    }
  }
  ${collectionListFragment}
`;

export const GQL_GET_COLLECTION = gql`
  query GetCollection($_id: ID!) {
    collection(_id: $_id) {
      ...CollectionFragment
    }
  }
  ${collectionFragment}
`;

export const GQL_COLLECTION_UPDATE = gql`
  mutation CollectionUpdate($collection: InputCollection) {
    collectionUpdate(collection: $collection) {
      ...CollectionFragment
    }
  }
  ${collectionFragment}
`;

export const GQL_COLLECTION_DELETE = gql`
  mutation CollectionDelete($_id: ID!) {
    collectionDelete(_id: $_id)
  }
`;

export const GQL_GET_COLLECTION_LIST = gql`
  query GetCollectionList($data: ListItems!) {
    collectionList(data: $data) {
      ...CollectionListFragment
    }
  }
  ${collectionListFragment}
`;
