import { gql } from '@apollo/client';

export const characteristicFragment = gql`
  fragment CharacteristicFragment on Characteristic {
    _id
    name
    slug
    type
    values {
      _id
      name
      slug
    }
    status
  }
`;

export const GQL_GET_CHARACTERISTICS = gql`
  query GetCharacteristics($query: QueryItems) {
    characteristics(query: $query) {
      total
      items {
        ...CharacteristicFragment
      }
    }
  }
  ${characteristicFragment}
`;

export const GQL_GET_CHARACTERISTIC = gql`
  query GetCharacteristic($_id: ID!) {
    characteristic(_id: $_id) {
      ...CharacteristicFragment
    }
  }
  ${characteristicFragment}
`;

export const GQL_CHARACTERISTIC_UPDATE = gql`
  mutation CharacteristicUpdate($characteristic: InputCharacteristic) {
    characteristicUpdate(characteristic: $characteristic) {
      ...CharacteristicFragment
    }
  }
  ${characteristicFragment}
`;

export const GQL_CHARACTERISTIC_DELETE = gql`
  mutation CharacteristicDelete($_id: ID!) {
    characteristicDelete(_id: $_id)
  }
`;

export const GQL_GET_CHARACTERISTIC_LIST = gql`
  query GetCharacteristicList($data: ListItems) {
    characteristicList(data: $data) {
      ...CharacteristicFragment
    }
  }
  ${characteristicFragment}
`;
