import { gql } from '@apollo/client';
import { fileFragment, metaFragment } from './common';
import { productListFragment } from './product';

const pageFragment = gql`
  fragment PageFragment on Page {
    _id
    meta {
      ...MetaFragment
    }
    name
    slug
    description
    status
    blocks {
      id
      type
      content
      container
      gridSpan
      title
      description
      category_ids
      categories {
        _id
        name
        slug
      }
      products {
        _id
        name
        slug
        count
        price
        discount
        status
        color {
          _id
          name
          code
          slug
          status
        }
        # Virtuals
        is_new
        files {
          path
          mime_type
          model
          model_id
          format
        }
        brand {
          _id
          name
          slug
          status
        }
      }
      news
      link
      file {
        _id
        path
        mime_type
        model
        model_id
        format
      }
      tools
      overlayColor
    }
  }
  ${metaFragment}
`;

export const GQL_GET_PAGES = gql`
  query GetPages($query: QueryItems) {
    pages(query: $query) {
      total
      items {
        ...PageFragment
      }
    }
  }
  ${pageFragment}
`;

export const GQL_GET_PAGE = gql`
  query GetPage($slug: String, $_id: ID) {
    page(_id: $_id, slug: $slug) {
      ...PageFragment
    }
  }
  ${pageFragment}
`;

export const GQL_PAGE_UPDATE = gql`
  mutation PageUpdate($page: InputPage) {
    pageUpdate(page: $page) {
      ...PageFragment
    }
  }
  ${pageFragment}
`;

export const GQL_PAGE_DELETE = gql`
  mutation PageDelete($_id: ID!) {
    pageDelete(_id: $_id)
  }
`;
