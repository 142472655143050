import { gql } from '@apollo/client';

export const colorFragment = gql`
  fragment ColorFragment on Color {
    _id
    code
    name
    slug
    status
  }
`;

export const GQL_GET_COLORS = gql`
  query GetColors($query: QueryItems) {
    colors(query: $query) {
      total
      items {
        ...ColorFragment
      }
    }
  }
  ${colorFragment}
`;

export const GQL_GET_COLOR = gql`
  query GetColor($_id: ID!) {
    color(_id: $_id) {
      ...ColorFragment
    }
  }
  ${colorFragment}
`;

export const GQL_COLOR_UPDATE = gql`
  mutation ColorUpdate($color: InputColor) {
    colorUpdate(color: $color) {
      ...ColorFragment
    }
  }
  ${colorFragment}
`;

export const GQL_COLOR_DELETE = gql`
  mutation ColorDelete($_id: ID!) {
    colorDelete(_id: $_id)
  }
`;

export const GQL_GET_COLOR_LIST = gql`
  query GetColorList($data: ListItems!) {
    colorList(data: $data) {
      ...ColorFragment
    }
  }
  ${colorFragment}
`;
