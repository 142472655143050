import { gql } from '@apollo/client';
import { characteristicFragment } from './characteristic';
import { colorFragment } from './color';

import { fileFragment, metaFragment } from './common';

const productFragment = gql`
  fragment ProductFragment on Product {
    _id
    meta {
      ...MetaFragment
    }
    name
    slug
    brand_id
    color_id
    file_ids
    category_id
    collection_id
    attached_ids
    similar_ids
    popularity
    sku
    description
    count
    price
    discount
    status
    characteristic_values {
      char_id
      status
      values {
        val_id
        status
      }
    }
    #Virtual fields
    color {
      ...ColorFragment
    }
    files {
      ...FileFragment
    }
    characteristics {
      ...CharacteristicFragment
    }
    is_new
    category {
      _id
      name
      slug
      status
      parent_id
      parent {
        _id
        name
        slug
        status
      }
    }
    brand {
      _id
      name
      slug
      status
    }
    attached {
      _id
      name
      files {
        ...FileFragment
      }
    }
    similar {
      _id
      name
      files {
        ...FileFragment
      }
    }
    collection {
      name
      slug
    }
  }
  ${characteristicFragment}
  ${colorFragment}
  ${fileFragment}
  ${metaFragment}
`;

export const productListFragment = gql`
  fragment ProductListFragment on Product {
    _id
    name
    slug
    popularity
    status
    # Virtuals
    is_new
    count
    price
    discount
    status
    files {
      ...FileFragment
    }
    category {
      _id
      name
      slug
      status
      parent_id
    }
    brand {
      _id
      name
      slug
      status
    }
  }
  ${fileFragment}
`;

export const GQL_GET_PRODUCTS = gql`
  query GetProducts($query: QueryItems) {
    products(query: $query) {
      total
      items {
        ...ProductListFragment
      }
    }
  }
  ${productListFragment}
`;

export const GQL_GET_PRODUCT = gql`
  query GetProduct($_id: ID!) {
    product(_id: $_id) {
      ...ProductFragment
    }
  }
  ${productFragment}
`;

export const GQL_PRODUCT_UPDATE = gql`
  mutation ProductUpdate($product: InputProduct) {
    productUpdate(product: $product) {
      ...ProductFragment
    }
  }
  ${productFragment}
`;

export const GQL_PRODUCT_DELETE = gql`
  mutation ProductDelete($_id: ID!) {
    productDelete(_id: $_id)
  }
`;

export const GQL_GET_PRODUCT_LIST = gql`
  query GetProductList($data: ListItems!) {
    productList(data: $data) {
      ...ProductListFragment
    }
  }
  ${productListFragment}
`;
