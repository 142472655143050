import { gql } from '@apollo/client';
import { metaFragment } from './common';

const brandFragment = gql`
  fragment BrandFragment on Brand {
    _id
    meta {
      ...MetaFragment
    }
    name
    slug
    description
    status
  }
  ${metaFragment}
`;

export const GQL_GET_BRANDS = gql`
  query GetBrands($query: QueryItems) {
    brands(query: $query) {
      total
      items {
        ...BrandFragment
      }
    }
  }
  ${brandFragment}
`;

export const GQL_GET_BRAND = gql`
  query GetBrand($_id: ID!) {
    brand(_id: $_id) {
      ...BrandFragment
    }
  }
  ${brandFragment}
`;

export const GQL_BRAND_UPDATE = gql`
  mutation BrandUpdate($brand: InputBrand) {
    brandUpdate(brand: $brand) {
      ...BrandFragment
    }
  }
  ${brandFragment}
`;

export const GQL_BRAND_DELETE = gql`
  mutation BrandDelete($_id: ID!) {
    brandDelete(_id: $_id)
  }
`;

export const GQL_GET_BRAND_LIST = gql`
  query GetBrandList($data: ListItems!) {
    brandList(data: $data) {
      ...BrandFragment
    }
  }
  ${brandFragment}
`;
