import { useEffect } from 'react';

/**
 * @name useClickOutside
 * Hook that alerts clicks outside the passed ref
 * @param {React.RefObject<HTMLElement>} ref - Ref to the element
 * @param {function} callback - Function to call when clicked outside
 * @param {boolean} excludeId - ID of the element to exclude from click like close btn
 */
const useClickOutside = (
  ref: React.RefObject<HTMLElement>,
  callback: () => void,
  excludeId?: string
) => {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const targetElement = event.target as HTMLElement;
      if (excludeId && targetElement.closest(`#${excludeId}`)) return;
      if (ref.current && !ref.current.contains(targetElement)) {
        callback();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, callback, excludeId]);
};

export { useClickOutside };
