import { gql } from '@apollo/client';

import { metaFragment } from './common';

const subCategoryFragment = gql`
  fragment SubCategoryFragment on Category {
    _id
    meta {
      ...MetaFragment
    }
    name
    slug
    description
    status
  }
  ${metaFragment}
`;

const categoryFragment = gql`
  fragment CategoryFragment on Category {
    _id
    meta {
      ...MetaFragment
    }
    name
    slug
    parent_id
    parent {
      ...SubCategoryFragment
    }
    subcategories {
      ...SubCategoryFragment
    }
    description
    status
  }
  ${metaFragment}
  ${subCategoryFragment}
`;

export const GQL_GET_CATEGORIES = gql`
  query GetCategories($parent_id: String, $query: QueryItems) {
    categories(parent_id: $parent_id, query: $query) {
      total
      items {
        ...CategoryFragment
      }
    }
  }
  ${categoryFragment}
`;

export const GQL_GET_CATEGORY = gql`
  query GetCategory($_id: ID!) {
    category(_id: $_id) {
      ...CategoryFragment
    }
  }
  ${categoryFragment}
`;

export const GQL_CATEGORY_UPDATE = gql`
  mutation CategoryUpdate($category: InputCategory) {
    categoryUpdate(category: $category) {
      ...CategoryFragment
    }
  }
  ${categoryFragment}
`;

export const GQL_CATEGORY_DELETE = gql`
  mutation CategoryDelete($_id: ID!) {
    categoryDelete(_id: $_id)
  }
`;

export const GQL_GET_CATEGORY_LIST = gql`
  query GetCategoryList($data: ListItems!) {
    categoryList(data: $data) {
      ...CategoryFragment
    }
  }
  ${categoryFragment}
`;
