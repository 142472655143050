import { gql } from '@apollo/client';

export const addressFragment = gql`
  fragment AddressFragment on Address {
    _id
    name
    city
    location
    description
    status
  }
`;

export const GQL_GET_ADDRESSES = gql`
  query GetAddresses($query: QueryItems) {
    addresses(query: $query) {
      total
      items {
        ...AddressFragment
      }
    }
  }
  ${addressFragment}
`;

export const GQL_GET_ADDRESS = gql`
  query GetAddress($_id: ID!) {
    address(_id: $_id) {
      ...AddressFragment
    }
  }
  ${addressFragment}
`;

export const GQL_ADDRESS_UPDATE = gql`
  mutation AddressUpdate($address: InputAddress) {
    addressUpdate(address: $address) {
      ...AddressFragment
    }
  }
  ${addressFragment}
`;

export const GQL_ADDRESS_DELETE = gql`
  mutation AddressDelete($_id: ID!) {
    addressDelete(_id: $_id)
  }
`;
