import styled from '@emotion/styled';
import { TextareaAutosize } from '@mui/material';
import React from 'react';
import { Global, css } from '@emotion/react';

// @ts-ignore - need to fix
import PragmaticaNextVF from '../../public/assets/fonts/pragmatica-next_vf.ttf';

export const GlobalStyle = () => (
  <Global
    styles={css`
      @font-face {
        font-family: 'Pragmatica Next VF';
        src: url(${PragmaticaNextVF}) format('truetype');
        font-style: normal;
        font-weight: 100 900;
      }
      :root {
        --dark: #000000;
        --gray-dark: #7b7b7b;
        --gray: #cccccc;
        --gray-light: #eeeeee;
        --white: #ffffff;
        --red: #dd463c;
        --gold: #ebd9b2;
        --metalic: linear-gradient(225deg, #f9e197 0%, #dfaf5d 100%);
        --primary: #de7e00;
      }

      body {
        margin: 0;
        padding: 0;
        font-family: 'Pragmatica Next VF', sans-serif;
      }

      .editor-container {
        width: 100%;
      }

      *,
      *::before,
      *::after {
        box-sizing: border-box;
      }

      .error-text {
        font-weight: 400;
        font-size: 0.75rem;
        line-height: 1.66;
        letter-spacing: 0.03333em;
        text-align: left;
        margin: 3px 14px 0;
        color: var(--red, #dd463c);
      }

      .w-100 {
        width: 100%;
      }
    `}
  />
);

export const Textarea = styled(TextareaAutosize)(
  ({ theme }) => css`
    box-sizing: border-box;
    width: 100%;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 8px 12px;
    border-radius: 3px;
    color: var(--dark);
    border: 1px solid #b9b9b9;

    &:hover {
      border-color: var(--gray-dark);
    }

    &:focus {
      border-width: 1px;
      border-color: var(--primary);
      box-shadow: 0 0 0 1px var(--primary);
    }

    // firefox

    &:focus-visible {
      outline: 0;
    }
  `
);
