import { gql } from '@apollo/client';

const assignmentFragment = gql`
  fragment AssignmentFragment on Assignment {
    _id
    owner_id
    owner {
      _id
      name
    }
    role_ids
    roles {
      _id
      name
      slug
    }
    status
  }
`;

const roleFragment = gql`
  fragment RoleFragment on Role {
    _id
    name
    slug
    rule_ids
    rules {
      _id
      name
    }
    status
  }
`;

const ruleFragment = gql`
  fragment RuleFragment on Rule {
    _id
    name
    rule
  }
`;

export const GQL_GET_ASSIGNMENTS = gql`
  query GetAssignments($query: QueryItems) {
    assignments(query: $query) {
      total
      items {
        ...AssignmentFragment
      }
    }
  }
  ${assignmentFragment}
`;

export const GQL_GET_ASSIGNMENT = gql`
  query GetAssignment($_id: ID!) {
    assignment(_id: $_id) {
      ...AssignmentFragment
    }
  }
  ${assignmentFragment}
`;

export const GQL_GET_ROLE_LIST = gql`
  query GetRoleList($data: ListItems!) {
    roleList(data: $data) {
      ...RoleFragment
    }
  }
  ${roleFragment}
`;

export const GQL_GET_RULE_LIST = gql`
  query GetRuleList($data: ListItems!) {
    ruleList(data: $data) {
      ...RuleFragment
    }
  }
  ${ruleFragment}
`;

export const GQL_ASSIGNMENT_UPDATE = gql`
  mutation AssignmentUpdate($_id: ID, $owner_id: ID!, $role_ids: [ID!], $status: String!) {
    assignmentUpdate(_id: $_id, owner_id: $owner_id, role_ids: $role_ids, status: $status) {
      ...AssignmentFragment
    }
  }
  ${assignmentFragment}
`;

export const GQL_ASSIGNMENT_DELETE = gql`
  mutation AssignmentDelete($_id: ID!) {
    assignmentDelete(_id: $_id)
  }
`;

export const GQL_GET_ROLES = gql`
  query GetRoles($query: QueryItems) {
    roles(query: $query) {
      total
      items {
        ...RoleFragment
      }
    }
  }
  ${roleFragment}
`;

export const GQL_GET_ROLE = gql`
  query GetRole($_id: ID!) {
    role(_id: $_id) {
      ...RoleFragment
    }
  }
  ${roleFragment}
`;

export const GQL_ROLE_UPDATE = gql`
  mutation RoleUpdate(
    $_id: ID
    $name: String!
    $slug: String!
    $rule_ids: [ID]!
    $status: String!
  ) {
    roleUpdate(_id: $_id, name: $name, slug: $slug, rule_ids: $rule_ids, status: $status) {
      ...RoleFragment
    }
  }
  ${roleFragment}
`;

export const GQL_ROLE_DELETE = gql`
  mutation RoleDelete($_id: ID!) {
    roleDelete(_id: $_id)
  }
`;

export const GQL_GET_RULES = gql`
  query GetRules($query: QueryItems) {
    rules(query: $query) {
      total
      items {
        ...RuleFragment
      }
    }
  }
  ${ruleFragment}
`;

export const GQL_GET_RULE = gql`
  query GetRule($_id: ID!) {
    rule(_id: $_id) {
      ...RuleFragment
    }
  }
  ${ruleFragment}
`;

export const GQL_RULE_UPDATE = gql`
  mutation RuleUpdate($_id: ID, $name: String!, $rule: String!) {
    ruleUpdate(_id: $_id, name: $name, rule: $rule) {
      ...RuleFragment
    }
  }
  ${ruleFragment}
`;

export const GQL_RULE_DELETE = gql`
  mutation RuleDelete($_id: ID!) {
    ruleDelete(_id: $_id)
  }
`;
