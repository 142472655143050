import { gql } from '@apollo/client';

const socialFragment = gql`
  fragment SocialFragment on Social {
    _id
    code
    type
    name
    url
    status
  }
`;

export const GQL_GET_SOCIALS = gql`
  query GetSocials($query: QueryItems) {
    socials(query: $query) {
      total
      items {
        ...SocialFragment
      }
    }
  }
  ${socialFragment}
`;

export const GQL_GET_SOCIAL = gql`
  query GetSocial($_id: ID!) {
    social(_id: $_id) {
      ...SocialFragment
    }
  }
  ${socialFragment}
`;

export const GQL_SOCIAL_UPDATE = gql`
  mutation SocialUpdate($social: InputSocial) {
    socialUpdate(social: $social) {
      ...SocialFragment
    }
  }
  ${socialFragment}
`;

export const GQL_SOCIAL_DELETE = gql`
  mutation SocialDelete($_id: ID!) {
    socialDelete(_id: $_id)
  }
`;
