import { useQuery } from '@apollo/client';
import { GridFilterModel, GridPaginationModel, GridSortModel } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import _ from 'lodash';

/**
 * Hook for working with data grid
 * @param {String} gql - query for fetching data graphql
 * @param {Number} page - current page
 * @param {Number} pageSize - number of items per page
 * @param {String} fetchPolicy - policy for fetching data
 * @param {Object} variables - additional variables for query
 */
const useItemsQuery = ({
  gql,
  page = 0,
  pageSize = 20,
  fetchPolicy,
  variables,
}: UseItemsQueryProps): UseItemsProps => {
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page,
    pageSize,
  });

  // Sort status
  const [sortModel, setSortModel] = useState<GridSortModel>([]);

  // Filtration state
  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [],
  });

  // Handling sorting and filtering for sending to the server
  const [sortItems, setSortItems] = useState<string>('');
  const [filterItems, setFilterItems] = useState<any>({});

  // Fetching data
  const { data, loading, error, refetch } = useQuery<
    {
      pages: ItemsResponse;
    },
    { query: QueryItems }
  >(gql, {
    variables: {
      ...variables,
      query: {
        page: paginationModel.page + 1,
        rowsPerPage: paginationModel.pageSize,
        filterItems: !_.isEmpty(filterItems) ? JSON.stringify(filterItems) : '', // Преобразуем фильтры в строку
        sortItems,
      },
    },
    fetchPolicy,
  });

  // Updating sort options when sortModel changes
  useEffect(() => {
    if (sortModel.length > 0) {
      const sort = sortModel[0];
      setSortItems(`${sort.field}-${sort.sort?.toLowerCase()}`);
    } else {
      setSortItems('');
    }
  }, [sortModel]);

  // Update filtering parameters when filterModel changes
  useEffect(() => {
    const filters: any = {};

    filterModel.items.forEach((filter) => {
      if (filter.value) {
        filters[filter.field] = filter.value;
      }
    });

    setFilterItems(filters);
  }, [filterModel]);

  // Pagination change handler
  const handlePaginationModelChange = (model: GridPaginationModel) => {
    setPaginationModel(model);
  };

  // Sort change handler
  const handleSortModelChange = (model: GridSortModel) => {
    setSortModel(model);
  };

  // Filtering change handler
  const handleFilterModelChange = (model: GridFilterModel) => {
    setFilterModel(model);
  };

  // Updating data when changing parameters
  useEffect(() => {
    refetch({
      ...variables,
      query: {
        page: paginationModel.page + 1,
        rowsPerPage: paginationModel.pageSize,
        filterItems: !_.isEmpty(filterItems) ? JSON.stringify(filterItems) : '', // Преобразуем фильтры в строку
        sortItems,
      },
    });
  }, [paginationModel, filterItems, sortItems, refetch]);

  return {
    data,
    error,
    loading,
    paginationModel,
    setPaginationModel,
    sortModel,
    setSortModel,
    filterModel,
    setFilterModel,
    handlePaginationModelChange,
    handleSortModelChange,
    handleFilterModelChange,
    refetch,
  };
};

useItemsQuery.defaultProps = {
  fetchPolicy: 'cache-and-network',
  page: 0,
  pageSize: 20,
  variables: {},
};

export { useItemsQuery };
