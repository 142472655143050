import loadable, { LoadableComponent } from '@loadable/component';
import React from 'react';
import { useQuery } from '@apollo/client';
import { Route, Switch } from 'react-router-dom';
import _ from 'lodash';

import { GQL_GET_ME } from './graphql/queries';
import MasterAdmin from './layout/MasterAdmin';

const fallback = () => (
  <div className="loader">
    <div className="bar" />
  </div>
);
// MAIN
const Dashboard = loadable(() => import('./screens/Dashboard'), {
  ssr: false,
  fallback: fallback(),
});
const Users = loadable(() => import('./screens/user/Users'), {
  ssr: false,
  fallback: fallback(),
});
const UserUpdate = loadable(() => import('./screens/user/UserUpdate'), {
  ssr: false,
  fallback: fallback(),
});
const Register = loadable(() => import('./screens/Register'), {
  ssr: false,
  fallback: fallback(),
});
const MainUpdate = loadable(() => import('./screens/main/MainUpdate'), {
  ssr: false,
  fallback: fallback(),
});
// RBAC
const Rbac = loadable(() => import('./screens/rbac'), {
  ssr: false,
  fallback: fallback(),
});
const AssignmentUpdate = loadable(() => import('./screens/rbac/assignment/AssignmentUpdate'), {
  ssr: false,
  fallback: fallback(),
});
const RoleUpdate = loadable(() => import('./screens/rbac/role/RoleUpdate'), {
  ssr: false,
  fallback: fallback(),
});
const RuleUpdate = loadable(() => import('./screens/rbac/rule/RuleUpdate'), {
  ssr: false,
  fallback: fallback(),
});
// ORDER
const OrderView = loadable(() => import('./screens/order/OrderView'), {
  ssr: false,
  fallback: fallback(),
});
const Orders = loadable(() => import('./screens/order/Orders'), {
  ssr: false,
  fallback: fallback(),
});
// PAGE
const PageUpdate = loadable(() => import('./screens/page/PageUpdate'), {
  ssr: false,
  fallback: fallback(),
});
const Pages = loadable(() => import('./screens/page/Pages'), {
  ssr: false,
  fallback: fallback(),
});
// SHOP
const Shop = loadable(() => import('./screens/shop'), {
  ssr: false,
  fallback: fallback(),
});
const BrandUpdate = loadable(() => import('./screens/shop/brand/BrandUpdate'), {
  ssr: false,
  fallback: fallback(),
});
const CharacteristicUpdate = loadable(
  () => import('./screens/shop/characteristic/CharacteristicUpdate'),
  {
    ssr: false,
    fallback: fallback(),
  }
);
const CategoryUpdate = loadable(() => import('./screens/shop/category/CategoryUpdate'), {
  ssr: false,
  fallback: fallback(),
});
const CollectionUpdate = loadable(() => import('./screens/shop/collection/CollectionUpdate'), {
  ssr: false,
  fallback: fallback(),
});
const ProductUpdate = loadable(() => import('./screens/shop/product/ProductUpdate'), {
  ssr: false,
  fallback: fallback(),
});
const ColorUpdate = loadable(() => import('./screens/shop/color/ColorUpdate'), {
  ssr: false,
  fallback: fallback(),
});
const Categories = loadable(() => import('./screens/shop/category/Categories'), {
  ssr: false,
  fallback: fallback(),
});
// COMMON
const Common = loadable(() => import('./screens/common'), {
  ssr: false,
  fallback: fallback(),
});
const MenuUpdate = loadable(() => import('./screens/common/menu/MenuUpdate'), {
  ssr: false,
  fallback: fallback(),
});
const SocialUpdate = loadable(() => import('./screens/common/social/SocialUpdate'), {
  ssr: false,
  fallback: fallback(),
});
const AddressUpdate = loadable(() => import('./screens/common/address/AddressUpdate'), {
  ssr: false,
  fallback: fallback(),
});
const SettingUpdate = loadable(() => import('./screens/common/setting/SettingUpdate'), {
  ssr: false,
  fallback: fallback(),
});
// HISTORY
const HistoryView = loadable(() => import('./screens/history/HistoryView'), {
  ssr: false,
  fallback: fallback(),
});
const Histories = loadable(() => import('./screens/history/Histories'), {
  ssr: false,
  fallback: fallback(),
});
// ERROR
const ErrorScreen = loadable(() => import('./screens/ErrorScreen'), {
  ssr: false,
  fallback: fallback(),
});

const RouteNavigator = () => {
  const { data } = useQuery(GQL_GET_ME);

  const me = _.get(data, 'me', {});

  const AdminMaster: any = (
    page: LoadableComponent<any>,
    props: {
      container: false | 'xs' | 'sm' | 'md' | 'lg' | 'xl';
      me: object | null;
    }
  ) => MasterAdmin(page, props);

  return (
    <Switch>
      <Route path="/" component={AdminMaster(Dashboard, { container: false, me })} exact />
      {/* users */}
      <Route path="/register" component={Register} exact />
      <Route path="/user" component={AdminMaster(Users, { container: false, me })} exact />
      <Route
        path="/user/update/:_id([0-9a-fA-F]{24})?"
        component={AdminMaster(UserUpdate, { container: 'md', me })}
        exact
      />
      {/* main page */}
      <Route path="/main" component={AdminMaster(MainUpdate, { container: 'xl', me })} exact />
      {/* orders */}
      <Route path="/order" component={AdminMaster(Orders, { container: false, me })} exact />
      <Route
        path="/order/:_id([0-9a-fA-F]{24})?"
        component={AdminMaster(OrderView, { container: 'md', me })}
        exact
      />
      {/* pages */}
      <Route path="/page" component={AdminMaster(Pages, { container: false, me })} exact />
      <Route
        path="/page/update/:_id([0-9a-fA-F]{24})?"
        component={AdminMaster(PageUpdate, { container: 'lg', me })}
        exact
      />
      {/* COMMON */}
      <Route
        path="/common/:section?"
        component={AdminMaster(Common, { container: false, me })}
        exact
      />
      {/* COMMON menu */}
      <Route
        path="/common/menu/update/:_id([0-9a-fA-F]{24})?"
        component={AdminMaster(MenuUpdate, { container: 'lg', me })}
        exact
      />
      {/* COMMON settings */}
      <Route
        path="/common/setting/update/:_id([0-9a-fA-F]{24})?"
        component={AdminMaster(SettingUpdate, { container: 'md', me })}
        exact
      />
      {/* COMMON social */}
      <Route
        path="/common/social/update/:_id([0-9a-fA-F]{24})?"
        component={AdminMaster(SocialUpdate, { container: 'md', me })}
        exact
      />
      {/* COMMON address */}
      <Route
        path="/common/address/update/:_id([0-9a-fA-F]{24})?"
        component={AdminMaster(AddressUpdate, { container: 'md', me })}
        exact
      />
      {/* SHOP */}
      <Route path="/shop/:section?" component={AdminMaster(Shop, { container: false, me })} exact />
      {/* SHOP brand */}
      <Route
        path="/shop/brand/update/:_id([0-9a-fA-F]{24})?"
        component={AdminMaster(BrandUpdate, { container: 'md', me })}
        exact
      />
      {/* SHOP category */}
      <Route
        path="/shop/category/:parent_id([0-9a-fA-F]{24})?"
        component={AdminMaster(Categories, { container: false, me })}
        exact
      />
      {/* SHOP category update */}
      <Route
        path="/shop/category/update/:_id([0-9a-fA-F]{24})?/:attach?"
        component={AdminMaster(CategoryUpdate, { container: 'md', me })}
        exact
      />
      {/* SHOP collection */}
      <Route
        path="/shop/collection/update/:_id([0-9a-fA-F]{24})?/:attach?"
        component={AdminMaster(CollectionUpdate, { container: 'md', me })}
        exact
      />
      {/* SHOP characteristic */}
      <Route
        path="/shop/characteristic/update/:_id([0-9a-fA-F]{24})?"
        component={AdminMaster(CharacteristicUpdate, { container: 'md', me })}
        exact
      />
      {/* SHOP product */}
      <Route
        path="/shop/product/update/:_id([0-9a-fA-F]{24})?"
        component={AdminMaster(ProductUpdate, { container: 'xl', me })}
        exact
      />
      {/* SHOP color */}
      <Route
        path="/shop/color/update/:_id([0-9a-fA-F]{24})?"
        component={AdminMaster(ColorUpdate, { container: 'sm', me })}
        exact
      />
      {/* HISTORY */}
      <Route path="/history" component={AdminMaster(Histories, { container: false, me })} exact />
      <Route
        path="/history/:_id([0-9a-fA-F]{24})?"
        component={AdminMaster(HistoryView, { container: 'md', me })}
        exact
      />
      {/* RBAC */}
      <Route path="/rbac/:section?" component={AdminMaster(Rbac, { container: false, me })} exact />
      <Route
        path="/rbac/assignments/update/:_id([0-9a-fA-F]{24})?"
        component={AdminMaster(AssignmentUpdate, { container: 'md', me })}
        exact
      />
      <Route
        path="/rbac/roles/update/:_id([0-9a-fA-F]{24})?"
        component={AdminMaster(RoleUpdate, { container: 'md', me })}
        exact
      />
      <Route
        path="/rbac/rules/update/:_id([0-9a-fA-F]{24})?"
        component={AdminMaster(RuleUpdate, { container: 'md', me })}
        exact
      />
      {/* ERROR */}
      <Route path="/*" component={ErrorScreen} exact />
    </Switch>
  );
};

export default RouteNavigator;
