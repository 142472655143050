import { gql } from '@apollo/client';

const menuFragment = gql`
  fragment MenuItemFragment on MenuItem {
    _id
    name
    url
    order
    status
    children {
      name
      url
      order
      status
    }
  }
`;

export const GQL_GET_MENUS = gql`
  query GetMenus($query: QueryItems) {
    menus(query: $query) {
      total
      items {
        _id
        name
        code
        status
        items {
          ...MenuItemFragment
        }
      }
    }
  }
  ${menuFragment}
`;

export const GQL_GET_MENU = gql`
  query GetMenu($_id: ID!) {
    menu(_id: $_id) {
      _id
      name
      code
      status
      items {
        ...MenuItemFragment
      }
    }
  }
  ${menuFragment}
`;

export const GQL_MENU_UPDATE = gql`
  mutation MenuUpdate($menu: InputMenu) {
    menuUpdate(menu: $menu) {
      _id
      name
      code
      status
      items {
        ...MenuItemFragment
      }
    }
  }
  ${menuFragment}
`;

export const GQL_MENU_DELETE = gql`
  mutation MenuDelete($_id: ID!) {
    menuDelete(_id: $_id)
  }
`;
