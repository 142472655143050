import { gql } from '@apollo/client';

const orderFragment = gql`
  fragment OrderFragment on Order {
    _id
    name
    number
    phone
    email
    address
    home
    apartment
    comment
    shipping
    agreement
    shop_id
    status
    created_at
    shop {
      name
      location
      description
    }
    products {
      _id
      sku
      name
      file {
        _id
        model
        path
        format
      }
      quantity
      color
      price
      discount
      characteristics {
        car_id
        name
        values {
          val_id
          name
        }
      }
    }
  }
`;

const orderListFragment = gql`
  fragment OrderListFragment on Order {
    _id
    number
    name
    phone
    email
    address
    home
    apartment
    shipping
    shop_id
    status
    created_at
    shop {
      name
      location
    }
    products {
      _id
      name
      quantity
      price
      discount
    }
  }
`;

export const GQL_GET_ORDERS_COUNT = gql`
  query GetOrdersCount($status: String) {
    ordersCount(status: $status)
  }
`;

export const GQL_GET_ORDER = gql`
  query getOrder($_id: ID!) {
    order(_id: $_id) {
      ...OrderFragment
    }
  }
  ${orderFragment}
`;

export const GQL_GET_ORDERS = gql`
  query GetOrders($query: QueryItems) {
    orders(query: $query) {
      total
      items {
        ...OrderListFragment
      }
    }
  }
  ${orderListFragment}
`;
