import { gql } from '@apollo/client';

export const metaFragment = gql`
  fragment MetaFragment on MetaTags {
    title
    description
    canonical
    robots
  }
`;

export const fileFragment = gql`
  fragment FileFragment on File {
    _id
    path
    mime_type
    model
    model_id
    format
    size
    height
    width
    order
  }
`;

export const GQL_UPDATE_SORT = gql`
  mutation UpdateSort($data: InputSort!) {
    updateSort(data: $data)
  }
`;

export const GQL_DELETE_FILE = gql`
  mutation DeleteFile($_id: ID!) {
    deleteUpload(_id: $_id)
  }
`;

export const GQL_CHANGE_STATUS = gql`
  mutation ChangeStatus($model: String!, $status: String!, $_id: ID!) {
    changeStatus(model: $model, status: $status, _id: $_id)
  }
`;

export const GQL_CREATE_COPY = gql`
  mutation CreateCopy($model: String!, $_id: ID!) {
    createCopy(model: $model, _id: $_id)
  }
`;

export const GQL_GET_FILES = gql`
  query GetFiles($ids: [ID!]) {
    files(ids: $ids) {
      ...FileFragment
    }
  }
  ${fileFragment}
`;
