import { gql } from '@apollo/client';

const meFragment = gql`
  fragment MeFragment on Me {
    _id
    email
    name
    status
    username
    assignment {
      roles {
        rules {
          rule
        }
      }
    }
  }
`;

const userFragment = gql`
  fragment UserFragment on User {
    _id
    email
    name
    username
    status
    created_at
    updated_at
    assignment {
      roles {
        name
      }
    }
  }
`;

const userAllFragment = gql`
  fragment UserAllFragment on Users {
    total
    items {
      ...UserFragment
    }
  }
  ${userFragment}
`;

export const GQL_LOGIN = gql`
  mutation Login($username: String!, $password: String!) {
    login(username: $username, password: $password) {
      ...MeFragment
    }
  }
  ${meFragment}
`;

export const GQL_SIGNUP = gql`
  mutation Signup($name: String!, $email: String!, $password: String!) {
    signup(name: $name, email: $email, password: $password) {
      ...MeFragment
    }
  }
  ${meFragment}
`;

export const GQL_USER_UPDATE = gql`
  mutation UserUpdate($user: UserInput) {
    userUpdate(user: $user) {
      ...UserFragment
    }
  }
  ${userFragment}
`;

export const GQL_USER_DELETE = gql`
  mutation UserDelete($_id: ID!) {
    userDelete(_id: $_id)
  }
`;

export const GQL_GET_ME = gql`
  query GetMe {
    me {
      ...MeFragment
    }
  }
  ${meFragment}
`;

export const GQL_GET_USERS = gql`
  query GetUsers($query: QueryItems) {
    users(query: $query) {
      ...UserAllFragment
    }
  }
  ${userAllFragment}
`;

export const GQL_GET_USER = gql`
  query GetUser($_id: ID, $username: String) {
    user(_id: $_id, username: $username) {
      ...UserFragment
    }
  }
  ${userFragment}
`;

export const GQL_GET_USER_LIST = gql`
  query GetUserList($data: ListItems!) {
    userList(data: $data) {
      ...UserFragment
    }
  }
  ${userFragment}
`;
