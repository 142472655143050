const config = {
  environment: 'production',
  api: {
    url: '/api/graphql',
    upload: '/api/upload',
  },
  url: {
    schema: 'https',
    domain: 'admin.psylook.ru',
    domainFrontend: 'psylook.ru',
    cloud: 'https://storage.yandexcloud.net/khoklomadevstage',
  },
};

export { config };
