import _ from 'lodash';

const _checkAccess = (rules: { rule: string }[], name: string): boolean =>
  !_.isEmpty(rules) && rules.some((rule) => rule && rule.rule === name);

/**
 * @name can
 * @description RBAC check if user has access to the action or view look to API /src/utilities/rbac.js
 * @param {UserAccess} { me, rule, rules }
 *    {Object|null} me
 *    {String} rule name like 'backend.users.update'
 *    {Array<String>} rules array of rule names if you want to check multiple rules
 * @returns {boolean} - true if user has access to the rule
 */
export const can = ({ me, rule: ruleName, rules: ruleNames }: UserAccess): boolean => {
  if (ruleNames && !_.isEmpty(ruleNames)) {
    return ruleNames.some((r) => can({ me, rule: r }));
  }
  const ruleParts = ruleName ? ruleName?.split('.') : [];
  const assignment = _.get(me, 'assignment', {});
  if (_.isEmpty(assignment)) {
    return false;
  }
  const roles = _.get(assignment, 'roles', []) as { rules: { rule: string }[] }[];
  // accumulate all rules from user roles
  const userRules = roles.reduce(
    (acc, role) => {
      const rules = _.get(role, 'rules', []);
      return acc.concat(rules);
    },
    [] as { rule: string }[]
  );

  let rulePath = '';
  // Here we try to find one of the rule variant in user roles
  // example, we have ruleName 'backend.users.update'
  // Break it to parts by ruleParts upper, then try here
  // 1) try 'backend' if not ->
  // 2) try 'backend.users' if not ->
  // 3) try 'backend.users.update' if not than not allowed
  return ruleParts.some((rn) => {
    rulePath = rulePath ? `${rulePath}.${rn}` : rn;
    return _checkAccess(userRules, rulePath);
  });
};
