import {
  Box,
  Container,
  createTheme,
  CssBaseline,
  Grid,
  ThemeProvider,
  Toolbar,
} from '@mui/material';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import ErrorBoundary from '../ErrorBoundary';
import Login from '../screens/Login';
import TopBar from './_bar';
import SideMenu from './_side';

const defaultTheme = createTheme({
  palette: {
    primary: {
      main: '#b06b00',
      light: '#cc8b00',
      dark: '#7e5200',
      contrastText: '#fff',
    },
    success: {
      main: '#814700',
    },
    secondary: {
      light: '#ff7961',
      main: '#f44336',
      dark: '#ba000d',
      contrastText: '#000',
    },
  },
  components: {
    MuiUseMediaQuery: {
      defaultProps: {
        noSsr: true,
      },
    },
  },
});

export default (
  Component: any,
  props: {
    container: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
    me: object | null;
  }
): any => {
  const { container, me } = props;

  const [open, setOpen] = useState(false);

  const toggle = () => setOpen(!open);

  return (masterProps: any) => {
    if (!_.get(me, '_id')) {
      return <Login />;
    }
    return (
      <ErrorBoundary {...masterProps}>
        <ThemeProvider theme={defaultTheme}>
          <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <TopBar {...{ open, toggle, me }} />
            <SideMenu {...{ open, toggle, me }} />
            <Box
              component="main"
              sx={{
                backgroundColor: (theme) =>
                  theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[900],
                flexGrow: 1,
                height: '100vh',
                overflow: 'auto',
              }}
            >
              <Toolbar />
              <Container maxWidth={container} sx={{ mt: 4, mb: 4 }}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Component {...masterProps} me={me} />
                  </Grid>
                </Grid>
              </Container>
            </Box>
          </Box>
        </ThemeProvider>
      </ErrorBoundary>
    );
  };
};
