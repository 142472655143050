// This is a list of statuses
export const STATUS_ACTIVE: string = 'active';
export const STATUS_INACTIVE: string = 'inactive';
export const STATUS_DELETED: string = 'deleted';
// This is a list of input types
export const INPUT_TYPE_TEXT = 'text';
export const INPUT_TYPE_TEXTAREA = 'textarea';
export const INPUT_TYPE_HIDDEN = 'hidden';
export const INPUT_TYPE_EDITOR = 'editor';
// This is a list of user roles
export const USER_ADMIN: string = 'admin';
// This is a list of filter field types
export const FIELD_TYPE_COLOR: string = 'color';
export const FIELD_TYPE_RADIO: string = 'radio';
export const FIELD_TYPE_CHECKBOX: string = 'checkbox';
export const FIELD_TYPE_CHECK_BTN: string = 'check_button';
// Image sizes
export const IMAGE_SIZE_SMALL = 250;
export const IMAGE_SIZE_MEDIUM = 600;
// MODELS
export const ASSIGMENT_MODEL: string = 'assigment';
export const ROLE_MODEL: string = 'role';
export const RULE_MODEL: string = 'rule';
export const BRAND_MODEL: string = 'brand';
export const CATEGORY_MODEL: string = 'category';
export const CHARACTERISTIC_MODEL: string = 'characteristic';
export const COLOR_MODEL: string = 'color';
export const MENU_MODEL: string = 'menu';
export const PAGE_MODEL: string = 'page';
export const PRODUCT_MODEL: string = 'product';
export const SETTING_MODEL: string = 'setting';
export const UPLOAD_MODEL: string = 'upload';
export const USER_MODEL: string = 'user';
export const HISTORY_MODEL: string = 'history';
export const COLLECTION_MODEL: string = 'collection';
export const ADDRESS_MODEL = 'address';
export const ORDER_MODEL = 'order';
// ACTIONS
export const ACTION_CREATE: string = 'create';
export const ACTION_UPDATE: string = 'update';
export const ACTION_DELETE: string = 'delete';
export const ACTION_SET: string = 'set';
// SOCIAL
export const SOCIAL_CHANNEL: string = 'channel';
export const SOCIAL_CONTACT: string = 'contact';
// RESERVED SLUGS
export const SLUG_HOME: string = 'home';
export const SLUG_LOGIN: string = 'login';
export const SLUG_LOGOUT: string = 'logout';
export const SLUG_SIGNUP: string = 'signup';
export const SLUG_ACCOUNT: string = 'account';
export const SLUG_CATALOG: string = 'catalog';
export const SLUG_PRODUCT: string = 'product';
export const SLUG_FAVORITES: string = 'favorites';
export const SLUG_ORDER: string = 'order';
export const SLUG_DELIVERY: string = 'delivery-terms';
export const SLUG_PAYMENT: string = 'payment-terms';
export const SLUG_PRIVACY: string = 'privacy-policy';

export const ORDER_NEW = 'new';
export const ORDER_PROCESSING = 'processing';
export const ORDER_COMPLETED = 'completed';
export const ORDER_CANCELED = 'canceled';

export const ORDER_STATUSES = {
  new: ORDER_NEW,
  processing: ORDER_PROCESSING,
  completed: ORDER_COMPLETED,
  canceled: ORDER_CANCELED,
};

// This is a list of named order statuses
export const ORDER_STATUSES_NAMES: { [key: string]: string } = {
  [ORDER_NEW]: 'Новый',
  [ORDER_PROCESSING]: 'В обработке',
  [ORDER_COMPLETED]: 'Выполнен',
  [ORDER_CANCELED]: 'Отменен',
};

// This is a list of reserved slugs
export const RESERVED_SLUGS: string[] = [
  SLUG_HOME,
  SLUG_LOGIN,
  SLUG_LOGOUT,
  SLUG_SIGNUP,
  SLUG_ACCOUNT,
  SLUG_CATALOG,
  SLUG_PRODUCT,
  SLUG_FAVORITES,
  SLUG_ORDER,
  SLUG_DELIVERY,
  SLUG_PAYMENT,
  SLUG_PRIVACY,
];

// This is list of reserved slugs for first level with names
export const RESERVED_SLUGS_NAMES: { [key: string]: string } = {
  [SLUG_HOME]: 'Главная',
  [SLUG_LOGIN]: 'Вход',
  [SLUG_LOGOUT]: 'Выход',
  [SLUG_SIGNUP]: 'Регистрация',
  [SLUG_ACCOUNT]: 'Аккаунт',
  [SLUG_CATALOG]: 'Каталог',
  [SLUG_PRODUCT]: 'Продукт',
  [SLUG_FAVORITES]: 'Избранное',
  [SLUG_ORDER]: 'Заказ',
  [SLUG_DELIVERY]: 'Доставка',
  [SLUG_PAYMENT]: 'Оплата',
  [SLUG_PRIVACY]: 'Политика конфиденциальности',
};

// This is a list of user social types
export const SOCIAL_TYPES: { [key: string]: string } = {
  [SOCIAL_CHANNEL]: 'Канал',
  [SOCIAL_CONTACT]: 'Контакт',
};

// This is a list of actions
export const ACTIONS: string[] = [ACTION_CREATE, ACTION_UPDATE, ACTION_DELETE, ACTION_SET];

// This is a list of actions
export const ACTION_LABELS: { [key: string]: string } = {
  [ACTION_CREATE]: 'Создать',
  [ACTION_UPDATE]: 'Обновить',
  [ACTION_DELETE]: 'Удалить',
  [ACTION_SET]: 'Установить',
};
// This is a list of models
export const MODELS: string[] = [
  ADDRESS_MODEL,
  ASSIGMENT_MODEL,
  COLLECTION_MODEL,
  ROLE_MODEL,
  RULE_MODEL,
  BRAND_MODEL,
  CATEGORY_MODEL,
  CHARACTERISTIC_MODEL,
  COLOR_MODEL,
  MENU_MODEL,
  PAGE_MODEL,
  PRODUCT_MODEL,
  SETTING_MODEL,
  UPLOAD_MODEL,
  USER_MODEL,
  HISTORY_MODEL,
  ORDER_MODEL,
];

export const MODEL_LABELS: { [key: string]: string } = {
  [ADDRESS_MODEL]: 'Адрес',
  [ASSIGMENT_MODEL]: 'Назначение',
  [COLLECTION_MODEL]: 'Коллекция',
  [ROLE_MODEL]: 'Роль',
  [RULE_MODEL]: 'Правило',
  [BRAND_MODEL]: 'Бренд',
  [CATEGORY_MODEL]: 'Категория',
  [CHARACTERISTIC_MODEL]: 'Характеристика',
  [COLOR_MODEL]: 'Цвет',
  [MENU_MODEL]: 'Меню',
  [PAGE_MODEL]: 'Страница',
  [PRODUCT_MODEL]: 'Продукт',
  [SETTING_MODEL]: 'Настройка',
  [UPLOAD_MODEL]: 'Загрузка',
  [USER_MODEL]: 'Пользователь',
  [HISTORY_MODEL]: 'История',
  [ORDER_MODEL]: 'Заказы',
};
// This is a list of names field types for the
export const FIELD_TYPES_NAME: { [key: string]: string } = {
  color: 'Цвет',
  radio: 'Радио',
  checkbox: 'Чекбокс',
  check_button: 'Кнопка чекбокс',
};
// This is a list of field types
export const FIELD_TYPES: { [key: string]: string } = {
  color: FIELD_TYPE_COLOR,
  radio: FIELD_TYPE_RADIO,
  checkbox: FIELD_TYPE_CHECKBOX,
  check_button: FIELD_TYPE_CHECK_BTN,
};
// This is a list of user statuses
export const USER_STATUSES: { [key: string]: string } = {
  active: STATUS_ACTIVE,
  inactive: STATUS_INACTIVE,
  deleted: STATUS_DELETED,
};
// This is a list of input types
export const INPUT_TYPES: { [key: string]: string } = {
  [INPUT_TYPE_TEXT]: 'Текст',
  [INPUT_TYPE_TEXTAREA]: 'Текстовая область',
  [INPUT_TYPE_EDITOR]: 'Редактор',
};
// This is a list of statuses
export const STATUSES: { [key: string]: string } = {
  active: STATUS_ACTIVE,
  inactive: STATUS_INACTIVE,
};
// This is a list of user roles
export const USER_STATUSES_TEXT: { [key: string]: string } = {
  [STATUS_ACTIVE]: 'Активный',
  [STATUS_INACTIVE]: 'Неактивный',
  [STATUS_DELETED]: 'Удален',
};
